<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-md-8 col-sm-12 col-xl-4">
        <vnud-card class="stacked-form" title="Edit Event">
          <template v-slot:header>
            <div>
              <div style="float: right">
                <img style="cursor:pointer" @click="goBack" width="20"
                  src="@/assets/img/glyphicons-basic-887-undo@2x.png" />
              </div>
              <h2>Edit Event</h2>
            </div>
          </template>
          <form @submit.prevent="saveEvent">
            <Alert :error-bag="customError.errorBag" class="mb-2" />
            <div>
              <fg-input v-model="name" label="Name" placeholder="Enter the event's title name" />
              <fg-input v-model="description" label="Description" placeholder="Describe the event" />

              <div class="form-group has-label">
                <label>Location</label>
                <v-autocomplete v-if="location || edited" v-model="location" variant="solo"  density="compact" :rounded="true" :items="filteredOptions"
                  placeholder="Where is the event?" class="custom-autocomplete" item-title="text" :return-object="true" v-model:search="searchQuery" :loading="loading"
                  no-data-text="No results found" hide-no-data @update:search="fetchData" @blur="handleBlur"
                  @focus="handleFocus"></v-autocomplete>
              </div>

              <!-- Start Date -->
              <label>Start Date</label>
              <v-dialog v-model="startDateMenu" width="290">
                <template #activator="{ props }">
                  <v-text-field v-model="startDateDisplay" v-bind="props" prepend-icon="mdi-calendar" readonly
                    label="Start Date" />
                </template>
                <v-date-picker v-model="startDate" :min="today" @update:modelValue="saveStartDate"></v-date-picker>
              </v-dialog>

              <!-- Start Time -->
              <label>Start Time</label>
              <v-dialog v-model="startTimeMenu" width="290">
                <template #activator="{ props }">
                  <v-text-field v-model="startTimeDisplay" v-bind="props" prepend-icon="mdi-clock-outline" readonly
                    label="Start Time" />
                </template>
                <v-time-picker v-model="startTime" :allowed-minutes="(m) => m % 15 === 0"
                  @update:modelValue="saveStartTime"></v-time-picker>
              </v-dialog>

              <!-- Toggle optional end date/time -->
              <div>
                <button type="button" class="btn btn-sm text-white" @click="showEnd = !showEnd">End Time</button>
              </div>

              <div v-if="showEnd">
                <!-- End Date -->
                <label>End Date</label>
                <v-dialog v-model="endDateMenu" width="290">
                  <template #activator="{ props }">
                    <v-text-field v-model="endDateDisplay" v-bind="props" prepend-icon="mdi-calendar" readonly
                      label="End Date" />
                  </template>
                  <v-date-picker v-model="endDate" :min="startDate" @update:modelValue="saveEndDate"></v-date-picker>
                </v-dialog>

                <!-- End Time -->
                <label>End Time</label>
                <v-dialog v-model="endTimeMenu" width="290">
                  <template #activator="{ props }">
                    <v-text-field v-model="endTimeDisplay" v-bind="props" prepend-icon="mdi-clock-outline" readonly
                      label="End Time" />
                  </template>
                  <v-time-picker v-model="endTime" :allowed-minutes="(m) => m % 15 === 0"
                    @update:modelValue="saveEndTime"></v-time-picker>
                </v-dialog>
              </div>

              <v-btn color="info" @click="saveEvent">Save Event</v-btn>
              <v-btn color="danger" @click="cancelEvent" style="float:right">Cancel Event</v-btn>
              <br>
              <small>Attendees will be notified of any event updates. Please keep updates to a minimum.</small>
            </div>
          </form>
        </vnud-card>
      </div>
    </div>

    <!-- Modal Alert for Errors -->
    <modal v-model:show="showAlert" class="modal-primary" :show-close="false">
      <p>{{ alertMessage }}</p>
      <template v-slot:footer>
        <v-btn @click="showAlert = false">Try again</v-btn>
      </template>
    </modal>
  </div>
</template>

<script>
// import swal from 'sweetalert2'
import { Modal, VnudCard } from '@/components/index.js'
import moment from 'moment'
import { apiPost, apiPut, apiGet } from '@/utils/api'
import { VTimePicker } from 'vuetify/labs/VTimePicker'
import Alert from "@/components/Alert.vue";
export default {
  components: {
    Modal,
    VnudCard,
    VTimePicker,
    Alert
  },
  inject: ["customError"],
  data() {
    return {
      id: '',
      name: '',
      description: '',
      location: '',
      startDate: new Date(), // Ensure startDate and endDate are valid Date objects
      startTime: '',
      endDate: '',
      endTime: '',
      filteredOptions: [],
      showAlert: false,
      alertMessage: '',
      selected: {},
      edited: false,
      startDateMenu: false,
      startTimeMenu: false,
      endDateMenu: false,
      endTimeMenu: false,
      showEnd: false,
      today: new Date(), // Ensure today is a Date object
      loading: false, // Loading state during API call
      searchQuery: '', // Holds user input for search
    }
  },
  computed: {
    startDateDisplay() {
      return this.startDate ? moment(this.startDate).format('MMM Do YY') : ''
    },
    startTimeDisplay() {
      return this.startTime ? moment(this.startTime, 'HH:mm').format('h:mm a') : ''
    },
    endDateDisplay() {
      return this.endDate ? moment(this.endDate).format('MMM Do YY') : ''
    },
    endTimeDisplay() {
      return this.endTime ? moment(this.endTime, 'HH:mm').format('h:mm a') : ''
    },
  },
  async mounted() {
    this.customError.resetErrorBag();
    this.id = this.$route.params.eventId
    const event = await apiGet(`/event/${this.id}`)
    this.name = event.name
    this.description = event.description
    this.location = await apiGet(`/location/${event.locationId}`)
    this.filteredOptions = []
    this.filteredOptions.push(this.location)
    this.startDate = new Date(moment.utc(event.start).format('YYYY-MM-DD'))
    this.startTime = moment.utc(event.start).format('HH:mm:ss')
    this.endDate = event.end ? new Date(moment.utc(event.end).format('YYYY-MM-DD')) : null
    this.endTime = event.end ? moment.utc(event.end).format('HH:mm:ss') : ''
    this.showEnd = !!event.end
  },
  methods: {
    saveStartDate(value) {
      this.startDate = new Date(value) // Ensure it's stored as a Date object
      this.startDateMenu = false
    },
    saveStartTime(value) {
      this.startTime = moment(value, 'HH:mm').format('HH:mm')
      this.startTimeMenu = false
    },
    saveEndDate(value) {
      this.endDate = new Date(value) // Ensure it's stored as a Date object
      this.endDateMenu = false
    },
    saveEndTime(value) {
      this.endTime = moment(value, 'HH:mm').format('HH:mm')
      this.endTimeMenu = false
    },
    saveEvent: async function () {
      if (!this.name || !this.description || !this.location || !this.startDate || !this.startTime) {
        this.alertMessage = 'Please enter all required Event data'
        this.showAlert = true
        return
      }

      const startDateTime = moment(this.startDate).format('YYYY-MM-DD') + ' ' + this.startTime
      const endDateTime = this.showEnd ? moment(this.endDate).format('YYYY-MM-DD') + ' ' + this.endTime : null

      if (moment(startDateTime).isAfter(endDateTime)) {
        this.alertMessage = 'Event start must be before its end'
        this.showAlert = true
        return
      }

      try {
        await apiPut(`/event/${this.id}`, {
          name: this.name,
          description: this.description,
          location: this.location.text,
          lat: this.location.lat,
          lng: this.location.lng,
          start: startDateTime,
          end: endDateTime,
        })
        this.$router.push(`/events/${this.id}`)
      } catch (error) {
        this.customError.transformValidationErrors(error.response);
      }
    },
    cancelEvent: async function () {
      const cancelEvent = await this.$swal.fire({
        title: 'Cancel Event?',
        text: 'Are you sure you want to cancel this event?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, cancel it!',
      })

      if (cancelEvent.isConfirmed) {
        const { value: reason } = await this.$swal.fire({
          title: 'Why are you canceling the event?',
          input: 'text',
          inputPlaceholder: 'Reason for canceling...',
          showCancelButton: true,
        })

        if (reason) {
          await apiPost(`/event/${this.id}/cancel`, { reason })
          this.$router.push('/events')
        }
      }
    },
    goBack() {
      this.$router.push(`/events/${this.id}`)
    },
    async fetchData(input) {
      if (!input) {
        this.options = [];
        return;
      }
      this.loading = true;
      try {
        let response = await apiPost('/location/search', { 'text': input })
        this.filteredOptions = response
        console.log(response);
      } catch (error) {
        console.error('Error fetching data:', error);
        this.filteredOptions = [];
      } finally {
        this.loading = false;
      }
    },
    handleBlur() {
      // If the input is not found in the options, use the typed input as the selected value
      const isLocationAvailable = this.filteredOptions.some(
        option => option.text === this.location.text && option.lat === this.location.lat && option.lng === this.location.lng
      );
      if (!isLocationAvailable || !this.location) {
        this.location = { text: this.searchQuery, lat: null, lng: null,  }
      }

      // Keep the placeholder visible by not clearing the searchQuery on blur unless a value is selected
      if (!this.location) {
        this.searchQuery = ''; // Resets to show placeholder if no option was selected
      }

      if (this.location === '' && this.searchQuery === '') {
        this.searchQuery = null;
        this.location = null;
      }
    },
    handleFocus() {
      if (!this.location) {
        this.searchQuery = ''; // Prepare input for new search on focus
      }
    },
  },
}
</script>

<style scoped>
.suggestion-item {
  cursor: pointer;
}

.autosuggest__results_item {
  list-style-type: none;
  padding: 5px;
  background-color: lavender;
}

ul[role="listbox"] {
  margin-top: 10px;
}

.time-control {
  width: 290px;
  margin-left: auto;
  margin-right: auto;
}
</style>
